import React, { Component } from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import {AppContext} from './contexts/AppContext'
import LoginForm from './Components/LoginForm'
import Main from './Components/Main'


export class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <AppContext.Provider value={{}}>
                    <Route path='/login' component={LoginForm} />
                    <Route exact path='/' component={Main} />
                </AppContext.Provider>
            </BrowserRouter>
        )
    }
}

export default App
