import React, { Component } from 'react'

export class Buttons extends Component {
    render() {
        const {label, loading} = this.props;
        return (
            <div className='form-group'>
                <button type='submit' className='btn btn-success'>
                    {loading ? <div className='loader'></div> : (label ? label : 'Submit')}
                </button>
                <button type='reset' className='btn btn-danger'>Reset Form</button>
            </div>
        )
    }
}

export default Buttons
